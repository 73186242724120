import React from 'react';
import './App.css';
import NavigationBar from './components/NavigationBar';
import FooterBar from './components/FooterBar'
import { BrowserRouter as Router, Routes, Route }
    from 'react-router-dom';
import Home from './pages';
import Challenges from './pages/challenges';
import Goals from './pages/goals';
import Info from './pages/info';
import Stats from './pages/stats';

function App() {
    return (
        <Router>
            <NavigationBar />
            <div className="wrapper">
              <Routes>
                  <Route exact path='/' element={<Home />} />
                  <Route path='/activities' element={<Challenges />} />
                  <Route path='/goals' element={<Goals />} />
                  <Route path='/info' element={<Info />} />
                  <Route path='/stats' element={<Stats />} />
              </Routes>
            </div>
            <FooterBar />
        </Router>
    );
}

export default App;