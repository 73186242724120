import React from "react";
import { Component } from "react";
import { socket } from '../socket';


function secondsToHHMMSS (seconds) {
    return (Math.floor(seconds / 3600)) + ":" + ("0" + Math.floor(seconds / 60) % 60).slice(-2) + ":" + ("0" + seconds % 60).slice(-2)
}

function secondsToHHMM (seconds) {
    return (Math.floor(seconds / 3600)) + "h " + ("0" + Math.floor(seconds / 60) % 60).slice(-2) + "m"
}
 
class Timer extends Component {
    constructor() {
        super();
        this.state = { loading: true };

    }
 
    async componentDidMount() {
        socket.on("timer", (timeLeft, timePassed) => {
            this.setState(Object.assign({ loading: false }, {time: timeLeft, elapsed: timePassed}))
        });
    }

    render() {
        let timer;
        let elapsedTime;

        let startDate = new Date("October 9, 2023 08:15:00 UTC");
        let currentDate = new Date();
        let diffTime = Math.abs(currentDate - startDate);
        if(!this.state.loading){
            timer = <span style={{fontSize:'40px', color:'#FF10F0', textShadow:'1px 1px #21EF80'}}>
                {secondsToHHMMSS(this.state.time)}
            </span>
            elapsedTime = <span style={{fontSize:'40px', color:'#FF10F0', textShadow:'1px 1px #21EF80'}}>
                {secondsToHHMM(diffTime/1000)}
            </span>
        } else {
            timer = <span></span>
            elapsedTime = <span></span>
        }
        return(
            <div className="text-neongreen-900 text-[40px]">
                Remaining: {timer} Uptime: {elapsedTime}
            </div>
        )
    }
 }

export default Timer;
