import React from "react";
import { Table } from 'flowbite-react';
import { Component } from "react";
import { Chart } from "react-google-charts";

function secondsToHHMM (seconds) {
  return (Math.floor(seconds / 3600)) + "h " + ("0" + Math.floor(seconds / 60) % 60).slice(-2) + "m"
}

class Stats extends Component {
  constructor() {
    super();
    this.state = { loading: true };
  }

  async componentDidMount() {
    const topCheerRes = await fetch(
      `/api/stats/top?type=cheer&interval=session`
    );
    const topDonoRes = await fetch(
      `/api/stats/top?type=tip&interval=session`
    );
    const lowTimeRes = await fetch(
      '/api/stats/lowtime'
    );
    const subathonPointsRes = await fetch(
      '/api/stats/subathonpoints'
    )
    const topCheerJson = await topCheerRes.json();
    const topDonoJson = await topDonoRes.json();
    const lowTimeJson = await lowTimeRes.json();
    const subathonPointsJson = await subathonPointsRes.json();
    this.setState(Object.assign({ loading: false }, {cheers: topCheerJson}, {lowTimeCount: lowTimeJson.times_below_sixty}, {donos: topDonoJson}, {subathonPoints: subathonPointsJson}));
  }

  render() {
    let pieData = [
      ['Type', 'Amount'],
      ["Subs", this.state.subathonPoints?.tierOne + this.state.subathonPoints?.tierTwo + this.state.subathonPoints?.tierThree],
      ["Bits",  this.state.subathonPoints?.cheers],
      ["Donations", this.state.subathonPoints?.tips]
    ];
    const pieOptions = {
      title: "Subathon Point Sources",
      pieHole: 0.4,
      is3D: false,
      backgroundColor: 'none'
    };

    let startDate = new Date("July 21, 2023 08:16:01 UTC");
    let currentDate = new Date();
    let diffTime = Math.abs(currentDate - startDate);
    return (
        <div>
            <h1>Statistics</h1>
            <div className="md:flex justify-center md:space-x-4">
              <div className="w-1/3 sm:w-3/3">
              <p className="main-subheading">Top Cheers</p>
              <Table hoverable>
              <Table.Head>
                  <Table.HeadCell>
                  Username
                  </Table.HeadCell>
                  <Table.HeadCell>
                  Amount
                  </Table.HeadCell>
              </Table.Head>
              <Table.Body className="divide-y">
                  {
                      this.state.cheers?.map(cheer => (
                          <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800" key={cheer.username}>
                          <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                              {cheer.username}
                              </Table.Cell>
                          <Table.Cell>
                              {cheer.total} bits
                          </Table.Cell>
                          </Table.Row>
                      ))
                  }
              </Table.Body>
              </Table>
              </div>
              <div className="w-1/3 sm:w-3/3">
              <p className="main-subheading">Top Donations</p>
              <Table hoverable >
              <Table.Head>
                  <Table.HeadCell>
                  Username
                  </Table.HeadCell>
                  <Table.HeadCell>
                  Amount
                  </Table.HeadCell>
              </Table.Head>
              <Table.Body className="divide-y">
                  {
                      this.state.donos?.map(dono => (
                          <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800" key={dono.username}>
                            <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                                {dono.username}
                            </Table.Cell>
                            <Table.Cell>
                              €{dono.total}
                            </Table.Cell>
                          </Table.Row>
                      ))
                  }
              </Table.Body>
              </Table>
              </div>
            </div>
            <div className="flex justify-center pt-4">
              <p className="font-normal text-white">Current Uptime: {secondsToHHMM(diffTime/1000)}</p>           
            </div>
            <div className="flex justify-center pt-4">
                <p className="font-normal text-white">We have dropped below 60 seconds <b>{this.state?.lowTimeCount}</b> times so far during the subathon.</p>   
            </div> 
            <div className="md:ml-24 pt-4">
            <Chart
              chartType="PieChart"
              width="100%"
              height="400px"
              data={pieData}
              options={pieOptions}
            />
            </div>
        </div>
    );
  }
}

export default Stats;