export const subathonGoals = {
  "goals": [
    {
      "goal_name": "Add 24 hours to the timer \u2705",
      "lower_bound": 0,
      "upper_bound": 1
    },
    {
      "goal_name": "Bad Webcam until 40 SP \u2705",
      "lower_bound": 2,
      "upper_bound": 20
    },
    {
      "goal_name": "2 Truths and a Lie \u2705",
      "lower_bound": 21,
      "upper_bound": 40
    },
    {
      "goal_name": "Stream with clown \u2705 ",
      "lower_bound": 41,
      "upper_bound": 80
    },
    {
      "goal_name": "Yoga every day during subathon \u2705 ",
      "lower_bound": 81,
      "upper_bound": 100
    },
    {
      "goal_name": "Twitch charades \u2705",
      "lower_bound": 101,
      "upper_bound": 200
    },
    {
      "goal_name": "Allow Schnappi for 24 hours \u2705",
      "lower_bound": 201,
      "upper_bound": 300
    },
    {
      "goal_name": "Let ChatGPT control my day",
      "lower_bound": 301,
      "upper_bound": 400
    },
    {
      "goal_name": "Doom Eternal Nightmare run",
      "lower_bound": 401,
      "upper_bound": 600
    },
    {
      "goal_name": "Hardcore Minecraft 5 hearts no hp regen \u2705",
      "lower_bound": 601,
      "upper_bound": 800
    },
    {
      "goal_name": "Peel an egg \u2705",
      "lower_bound": 801,
      "upper_bound": 1000
    },
    {
      "goal_name": "Get my world record back in SOOPAH DOOPAH POOPAH \u2705",
      "lower_bound": 1001,
      "upper_bound": 1200
    },
    {
      "goal_name": "Chat decides my dinner \u2705",
      "lower_bound": 1201,
      "upper_bound": 1600
    },
    {
      "goal_name": "Smoothie Challenge \u2705",
      "lower_bound": 1601,
      "upper_bound": 2000
    },
    {
      "goal_name": "Create my own Golf with your friends map",
      "lower_bound": 2001,
      "upper_bound": 2400
    },
    {
      "goal_name": "Sushi Stream (Dave The Diver inspired) \u2705",
      "lower_bound": 2401,
      "upper_bound": 2800
    },
    {
      "goal_name": "Viewer tournament",
      "lower_bound": 2801,
      "upper_bound": 3200
    },
    {
      "goal_name": "Chat buys me a costume and I wear it for 24 hours \u2705",
      "lower_bound": 3201,
      "upper_bound": 3600
    },
    {
      "goal_name": "Road trip to nearby city \u2705",
      "lower_bound": 3601,
      "upper_bound": 4000
    },
    {
      "goal_name": "Cook something from the warcrimes channel",
      "lower_bound": 4001,
      "upper_bound": 5000
    },
    {
      "goal_name": "DNA test (heritage)",
      "lower_bound": 5001,
      "upper_bound": 6000
    },
    {
      "goal_name": "Cut my own hair \u2705",
      "lower_bound": 6001,
      "upper_bound": 7000
    },
    {
      "goal_name": "Smoothie Challenge Part 2",
      "lower_bound": 7001,
      "upper_bound": 9000
    },
    {
      "goal_name": "Maid Costume all day \u2705",
      "lower_bound": 9001,
      "upper_bound": 10000
    },
    {
      "goal_name": "Dinner with a stranger on stream",
      "lower_bound": 10001,
      "upper_bound": 12000
    },
    {
      "goal_name": "Try make up",
      "lower_bound": 12001,
      "upper_bound": 14000
    },
    {
      "goal_name": "Buy a green screen for game shows \u2705",
      "lower_bound": 14001,
      "upper_bound": 14500
    },
    {
      "goal_name": "VR Skyrim challenge (24 hours inside of Skyrim with themed food prepared)",
      "lower_bound": 14501,
      "upper_bound": 15000
    },
    {
      "goal_name": "Work out on stream every day for a week (Calisthenics or outdoor gym) \u2705",
      "lower_bound": 15001,
      "upper_bound": 16000
    },
    {
      "goal_name": "Allow schnappi \u2705",
      "lower_bound": 16001,
      "upper_bound": 17000
    },
    {
      "goal_name": "Ban schnappi \u2705",
      "lower_bound": 17001,
      "upper_bound": 17200
    },
    {
      "goal_name": "Chat decides if I go blonde or add highlights to my hair",
      "lower_bound": 17201,
      "upper_bound": 18000
    },
    {
      "goal_name": "Remove ICE CREAM SO GOOD from my soundboard, also remove ICECREAMSOGOOD emote \u2705",
      "lower_bound": 18001,
      "upper_bound": 18500
    },
    {
      "goal_name": "Visit Big Forsen (MEGA RIVER)",
      "lower_bound": 18501,
      "upper_bound": 19000
    },
    {
      "goal_name": "Proper IRL setup for Twitchcon NA \u2705",
      "lower_bound": 19001,
      "upper_bound": 20000
    },
    {
      "goal_name": "Let chat buy an outit for me \u2705",
      "lower_bound": 20001,
      "upper_bound": 27000
    },
    {
      "goal_name": "Try Pilk (Pepsi and Milk)",
      "lower_bound": 27001,
      "upper_bound": 28000
    },
    {
      "goal_name": "Learn the WeiWeiWeiWei dance",
      "lower_bound": 28001,
      "upper_bound": 30000
    },
    {
      "goal_name": "HIGH EFFORT FEMBOY STREAM 24 HOURS",
      "lower_bound": 30001,
      "upper_bound": 60000
    },
  ]
}