import React from "react";
import { Navbar } from 'flowbite-react';

const NavigationBar = () => {
    return (
        <Navbar fluid rounded className="bg-transparent">
            <Navbar.Brand
                href="/"
            >
                <span className="self-center whitespace-nowrap text-xl font-semibold dark:text-white">
                
                </span>
            </Navbar.Brand>
            <Navbar.Toggle />
            <Navbar.Collapse>
                <Navbar.Link href="/" className="text-white font-semibold text-lg">
                    <p>Welcome</p>
                </Navbar.Link>
                <Navbar.Link href="/activities" className="text-white font-semibold text-lg">
                    <p>Activities</p>
                </Navbar.Link>
            </Navbar.Collapse>
        </Navbar>
    );
};
 
export default NavigationBar;