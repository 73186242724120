import React, { Component } from "react";
import Slider from "react-slick";

function dateDiff() {
    var date1, date2;  

    date1 = new Date("10/09/2023");  
    date2 = new Date();  

    var time_difference = date2.getTime() - date1.getTime();  

    return Math.floor(time_difference / (1000 * 60 * 60 * 24));  
}

class Challenges extends Component {
    constructor() {
        super();
        this.settings = {
            className: "center",
            centerMode: true,
            infinite: true,
            centerPadding: "60px",
            slidesToShow: 3,
            speed: 500,
            responsive: [
                {
                  breakpoint: 600,
                  settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    centerMode: false
                  }
                }
              ]
          };
          this.numberChallenges = [109, 1010, 1011, 1012, 1013, 1014, 1015];
    }

    async componentDidMount() {
        this.slider.slickGoTo(dateDiff(), false);
    }
    
    render() {
        return (
            <div>
                <h1>
                    Daily activities
                </h1>
                <div className="challengeContainer">
                    <Slider ref={slider => (this.slider = slider)} {...this.settings}>
                    {this.numberChallenges.map(function(slide) {
                        return (
                        <div key={slide}>
                            <img src={"/images/challenges/" + slide + ".gif"} alt={slide} />
                        </div>
                        );
                    })}
                    </Slider>
                </div>
                
            </div>
        );
    }
    
};
 
export default Challenges;