
import React, { useRef } from 'react';
import { TwitchEmbed } from 'react-twitch-embed';
import Timer from './timer';

const Home = () => {
    const embed = useRef();
    const handleReady = (e) => {
        embed.current = e;
      };

    return (
        <div>
            <h1 className='text-center'>The Trizze Streamathon</h1>
            <h2 className='text-center'><span className='text-white'>Highscore </span><span className='text-neongreen-900'>64d 4h 12m</span></h2>
            <div className='welcomeStarOne fixed left-16 z-10 bottom-0 mb-[450px] invisible sm:visible'></div>
            <div className='welcomeStarTwo fixed left-24 z-10 bottom-0 mb-[690px] invisible sm:visible'></div>
            <div className='welcomeStarThree fixed left-14 z-10 bottom-0 mb-[880px] invisible lg:visible'></div>
            <div className='welcomeLampost fixed inset-x-0 bottom-0 h-12 z-10 left-10 invisible sm:visible'></div>
            <div className='welcomeMan fixed inset-x-0 bottom-0 h-12 z-10 ml-44 invisible sm:visible'></div>
            <div className='welcomeTree fixed bottom-0 right-0 h-16 w-64 z-10 invisible sm:visible'></div>
            <div className='welcomeStarOne fixed right-16 z-10 bottom-0 mb-[450px] invisible sm:visible'></div>
            <div className='welcomeStarTwo fixed right-24 z-10 bottom-0 mb-[690px] invisible sm:visible'></div>
            <div className='welcomeStarThree fixed right-14 z-10 bottom-0 mb-[880px] invisible lg:visible'></div>
            <div className={'flex justify-center invisible max-h-0.5 sm:visible sm:max-h-full'}>
                <TwitchEmbed channel="trizze" muted darkMode={true} hideControls={false} onVideoReady={handleReady} parent="trizze.net" withChat={false}/>
            </div>
            <div className={'flex justify-center'}><Timer /></div>
        </div>
    );
};
 
export default Home;