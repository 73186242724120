import React from "react";
 
const Info = () => {
    return (
        <div>
            <h1>
                HOW IT WORKS
            </h1>
            <div className="text-white">
                <div className="float-right max-w-xs">
                    <img src="/images/trizze-profile.png" alt="Trizze behind bars"/>
                </div>
                <p>A subathon is a marathon stream which lets viewers extend the stream duration through subs/donos/bits.</p>
                <br />
                <p>--------------------------------------------------------------</p>
                <br />
                <div>
                    <div style={{display: 'block', float: 'left'}}>
                        <p>Increasing SP:</p>
                        <ul>
                            <li>- Tier 1 sub: 4 SP</li>
                            <li>- Tier 2 sub: 8 SP</li>
                            <li>- Tier 3 sub: 20 SP</li>
                            <li>- Tips: 1 SP per EUR</li>
                            <li>- Bits: 1 SP per 100 bits</li>
                        </ul>
                    </div>
                    <div style={{display: 'block', float: 'left'}} className="md:pl-5">
                        <p>Increasing time:</p>
                        <ul>
                        <li>- Tier 1 sub: 5 min</li>
                        <li>- Tier 2 sub: 10 min</li>
                        <li>- Tier 3 sub: 25 min</li>
                        <li>- Tips: 1 min per EUR</li>
                        <li>- Bits: 1 min per 100 bits</li>
                        <li>- Merch: 1 min per USD</li>
                        <li>- Raids: 10 sec per viewer</li>
                        <li>- Followers: 60 sec per follower</li>
                        </ul>
                    </div>
                </div>
                <div style={{display: 'inline-grid'}}>
                <br />
                <p>--------------------------------------------------------------</p>
                <br />
                <p>Subathon points:</p>
                <p>Subs/Donos/Bits also adds Subathon points (SP)</p>
                <p>As the community collectively accumulates Subathon Points (SP), certain <a href="/goals" className="underline">goals</a> are unlocked.</p>
                <br />
                <p>--------------------------------------------------------------</p>
                <br />
                <p>Daily Activities:</p>
                <p>There are also <a href="/challenges" className="underline">daily activities</a>, these can vary in length but should be done each day. If a daily activity is skipped, 8 hours are added to the timer.</p>
                <br />
                <p>--------------------------------------------------------------</p>
                <br />
                <p>Join the subathon to support Trizze's full-time streaming journey and enjoy the rewards! 🚀</p>
                <br />
                </div>
            </div>
        </div>
    );
};
 
export default Info;