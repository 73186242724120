import React, { Component } from "react";
import { Progress } from 'flowbite-react';
import { socket } from '../socket';
import { subathonGoals } from '../subathonGoals';

class Goals extends Component {

    constructor() {
        super();
        this.state = { loading: true };
    }

    async componentDidMount() {
        socket.on("goals", (cheers, tierOneSubs, tierTwoSubs, tierThreeSubs, tips) => {
            this.setState(Object.assign({ loading: false }, {cheers: cheers, tierOneSubs: tierOneSubs, tierTwoSubs: tierTwoSubs, tierThreeSubs: tierThreeSubs, tips: tips, totalSubPoints: Math.round(cheers + tierOneSubs + tierTwoSubs + tierThreeSubs + tips)}))
        });
    }

    render() {
        const self = this;
        if(!this.state.loading){
            var remaining_goals = subathonGoals.goals.filter(function(item){
                return self.state.totalSubPoints < item.upper_bound;
            });
            var progressBar = <Progress
                labelProgress
                labelText
                progress={(this.state.totalSubPoints/remaining_goals[0].upper_bound*100).toFixed(1)}
                progressLabelPosition="inside"
                size="xl"
                textLabel={"SP " + this.state.totalSubPoints + '/' + remaining_goals[0].upper_bound}
                textLabelPosition="outside"
                color="purple"
            />
            var b1 = new Set(remaining_goals);
            var difference = [...new Set(subathonGoals.goals.filter(x => !b1.has(x)))];
            var completed_goals_list = difference.map(goal => (<li key={goal.goal_name}>{goal.upper_bound} SP - {goal.goal_name}</li>))
            var remaining_goals_list = remaining_goals.map(goal => (<li key={goal.goal_name}>{goal.upper_bound} SP - {goal.goal_name}</li>))
        } else {
            progressBar = <span></span>
        }
        return (
            <div className="pb-4">
                <h1>Subathon goals</h1>
                <div className="text-white">
                    {progressBar}
                </div>
                <div className="pt-5">
                    <ul className="text-neongreen-900">
                        {completed_goals_list}
                    </ul>
                    <ul className="text-white">
                        {remaining_goals_list}
                    </ul>
                </div>  
            </div>
        )
        
    };
};
 
export default Goals;